.incorporated-blurb {
    font-size: 0.8rem;
    margin-top: 1rem;
    text-align: center;
    color: #595959;
    a {
        color: #595959;
        &:hover {
        color: #595959;
        }
    }
}

.copyright-blurb {
    font-size: 0.8rem;
    margin-top: 1rem;
    text-align: center;
    color: #595959;
    a {
        color: #595959;
        &:hover {
        color: #595959;
        }
    }
}

.access-logo {
    padding-top: -5rem;
}