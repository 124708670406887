@import '../colours';

.govuk-warning-text__icon {
    border: 3px solid govuk-colour("black");

    color: govuk-colour("white");
    background: govuk-colour("black");

    @media screen and (forced-colors: active) {
      border-color: windowText;
      color: windowText;
    }
}

.govuk-warning-text__text {
    @include govuk-text-colour;
}