@import '../colours';

.govuk-file-upload {

    &:focus {
        outline: $govuk-focus-width solid $govuk-focus-colour;
        box-shadow: inset 0 0 0 4px $govuk-input-border-colour;

        @include govuk-if-ie8 {
            border: 4px solid $govuk-input-border-colour;
        }
    }

    &:focus-within {
        outline: $govuk-focus-width solid $govuk-focus-colour;

        box-shadow: inset 0 0 0 4px $govuk-input-border-colour;
    }
}