@import '../colours';

.govuk-details__summary {
    color: $govuk-link-colour;

    &:hover {
        color: $govuk-link-hover-colour;
    }

    &:focus {
        @include govuk-focused-text;
    }
}

.govuk-details__text {
    border-left: $govuk-border-width solid $govuk-border-colour;
}