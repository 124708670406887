@import '../colours';

.govuk-checkboxes__input {
    @include govuk-if-ie8 {
        &:focus {
            outline: $govuk-focus-width solid $govuk-focus-colour;
        }
    }
}

@include govuk-not-ie8 {
    .govuk-checkboxes__label:before {
        border: $govuk-border-width-form-element solid currentcolor;
        background: transparent;
    }

    .govuk-checkboxes__label:after {
        border-top-color: transparent;
        opacity: 0;
        background: transparent;
    }
}

// Focused state
.govuk-checkboxes__input:focus + .govuk-checkboxes__label:before {
    outline: $govuk-focus-width solid transparent;

    @media screen and (forced-colors: active), (-ms-high-contrast: active) {
        outline-color: Highlight;
    }

    box-shadow: 0 0 0 $govuk-focus-width $govuk-focus-colour;
}

.govuk-checkboxes__divider {
    @include govuk-text-colour;
}

.govuk-checkboxes__conditional {
    border-left: 4px solid $govuk-border-colour;
}

.govuk-checkboxes--small {
    .govuk-checkboxes__item:hover .govuk-checkboxes__input:not(:disabled) + .govuk-checkboxes__label:before {
        box-shadow: 0 0 0 $govuk-hover-width $govuk-hover-colour;
    }
    .govuk-checkboxes__item:hover .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before {
        box-shadow:
        0 0 0 $govuk-focus-width $govuk-focus-colour, // 1
        0 0 0 $govuk-hover-width $govuk-hover-colour; // 2
    }

    @media (hover: none), (pointer: coarse) {
        .govuk-checkboxes__item:hover .govuk-checkboxes__input:focus + .govuk-checkboxes__label:before {
            box-shadow: 0 0 0 $govuk-focus-width $govuk-focus-colour;
        }
    }
}