@import '../colours';

.govuk-summary-list__row {
    border-bottom: 1px solid $govuk-border-colour;
}

@include govuk-media-query($until: tablet) {
    .govuk-summary-list__actions-list-item:not(:last-child) {
      border-right: 1px solid $govuk-border-colour;
    }
}

@include govuk-media-query($from: tablet) {
    .govuk-summary-list__actions-list-item:not(:first-child) {
      border-left: 1px solid $govuk-border-colour;
    }
}