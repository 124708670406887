.error-page-main {
    min-height: calc(100vh - 380px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-page-container {
    display: block;
    background: #fff;
    padding: 2rem;
    border-radius: 4px;
    border: 1px solid #ddd;
    text-align: center;
}