@import '../colours';

.portal__link {
  color: $govuk-link-colour;
  text-decoration: none;

  &:hover {
    color: $govuk-link-hover-colour;
    text-decoration: underline;
  }
  &:visited {
    color: $govuk-link-visited-colour;
    text-decoration: underline;
  }
}