#assessment {
    .panel__container {

        .govuk-grid-row {
            margin-right: 0;
            margin-left: 0;
        }
    }
}

#financial-assessment {
    .panel__container {
  
        .govuk-grid-row {
            margin-right: 0;
            margin-left: 0;
        }
    }
  }