@import '../colours';

.govuk-table {
    @include govuk-text-colour;

    &.responsive-table {
        @media (max-width: 768px) {
            font-size: 1em !important;

            thead {
                display: none;
            }

            tbody tr {
                display: inline-block;
                width: 100%;
                margin-bottom: 1em;
                padding-bottom: 1em;
            }

            tbody td,
            th {
                width: 100%;
                border-bottom: none;
                display: block;
                padding: 0.4em 0px 0.4em 0px;

                &:before {
                    content: attr(data-th)": ";
                    font-weight: bold;
                    margin-bottom: 0.2em;
                    display: block;
                }
            }
        }
    }
}

.govuk-table__header,
.govuk-table__cell {
    border-bottom: 1px solid $govuk-border-colour;
}