@import "~govuk-frontend/govuk/base";
@import '../../../assets/scss/colours';

.profileCard__wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid $govuk-border-colour;
	padding: 1rem;
  margin-bottom: 1.25rem;
	width: 100%;
	min-height: 100px;

	@include govuk-media-query($from: desktop) {
		min-height: 200px;
	}

	&:last-child {
		margin-right: 1.25rem;
	}
}

.profileCard__row {
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: left;

  @include govuk-media-query($from: desktop) {
		flex-direction: row;
    align-items: center;
	}
}

.profileCard__row > :first-child {
  width: 10rem;
}

.profileCard__row > :nth-child(2) {
  padding-right: 1rem;
  flex: 1;
}

.actions__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.profile__actions {
  display: flex;
  flex-direction: row;
  width: 200px;
}

.profile__actions > * {
  margin-right: 1rem;
}