@import '../colours';

.govuk-input {
    border: $govuk-border-width-form-element solid $govuk-input-border-colour;

    &:focus {
      outline: $govuk-focus-width solid $govuk-focus-colour;
    }

    &.readonly {
      opacity: 0.5;
      color: inherit;
      background-color: transparent;
      cursor: not-allowed;
    }
}

.govuk-input--error {
    border-color: $govuk-error-colour;

    &:focus {
      border-color: $govuk-input-border-colour;
    }
}

.govuk-input__prefix,
.govuk-input__suffix {
    border: $govuk-border-width-form-element solid $govuk-input-border-colour;
    background-color: govuk-colour("light-grey", $legacy: "grey-3");
}