@import '../colours';

.govuk-textarea {
    border: $govuk-border-width-form-element solid $govuk-input-border-colour;
    margin-bottom: 0;

    &:focus {
      outline: $govuk-focus-width solid $govuk-focus-colour;
    }
}

.govuk-textarea--error {
    border-color: $govuk-error-colour;

    &:focus {
      border-color: $govuk-input-border-colour;
    }
}