@import "~govuk-frontend/govuk/base";

.header__nav {
  margin: 0;
  
  &-logo img {
    margin: 0.25rem 0;

    @media (min-width: 51.25rem) {
      margin-right: 3.5rem;
    }

    @media (min-width: 64rem) {
      margin-right: 0;
    }
  }

  &-text {
    flex-grow: 6;
    font-size: 2.75rem;
    line-height: 2rem;
    font-weight: bold;
  }

  &-greeting {
    margin-right: 1rem;
    line-height: 2.5rem;
    font-size: 1.1875rem;
    text-decoration: none;

    & .govuk-button {
      margin-bottom: 1rem;
    }
  }
}

.govuk-header {
  border-bottom-width: 0;
}

.govuk-header__container {
  border-top-width: 5px;
}

.cls-1 {
  fill: var(--tenant-acg-font-colour, #E5173F);
}
.cls-2 {
  fill: var(--tenant-acg-font-colour, #58595B);
}

.header__nav-logo {
  max-height: 45px;
}

.govuk-header__with-tenant-logo {
  display: flex;
  justify-content: center;
  padding-right: 0;

  @include govuk-media-query($from: desktop) {
    width: 100%;
    float: none;
    justify-content: space-between;
  }
}

.govuk-header__access-logo {
  display: flex;
  justify-content: center;
  padding-right: 0;

  @include govuk-media-query($from: desktop) {
    width: 100%;
    float: none;
    justify-content: flex-end;
  }
}

.govuk-header__content {
  display: flex;
  justify-content: center;

  @include govuk-media-query($from: desktop) {
    width: 100%;
    float: none;
    padding-right: 0;
    justify-content: flex-end;
  }
}

.govuk-select {
  margin-bottom: 0.625rem;

  @media (min-width: 51.25rem) {
    margin-right: 3.5rem;
  }

  @media (min-width: 64rem) {
    margin-bottom: 0.125rem;
    margin-right: 0;
  }
}
