@import "~govuk-frontend/govuk/base";
@import "../../assets/scss/colours";

.govuk-form-group {
  margin-bottom: 0.625rem;
  
  @include govuk-media-query($from: desktop) {
      margin-bottom: 0.25rem;
  }
}


.menu_button {
  color: var(--tenant-acg-font-colour, #333333) !important;
  height: 100%;
  font-family: "GDS Transport", arial, sans-serif;
  font-weight: 400;
  font-size: 1.1875rem;
  line-height: 2.5rem;

  @include govuk-media-query($from: mobile) {
    white-space: normal;
    word-break: keep-all;
  }
}

.menu_drawer_item {
  margin: 0rem 0 0rem 0;
  font-size: 1.5rem;
  max-width: 100%;
  @include govuk-media-query($until: tablet) {
    margin: 3rem 0 3rem 0;
    font-size: 1rem;
  }
}


.dropdown_menu {
  padding: 0rem 1rem 0rem 0;
}

.profile_label {
  font-size: 1rem;
}

.checkmark_icon {
  order: 1;
}

.ant-menu {
  color: #333333;
  height: 100%;
  font-family: "GDS Transport", arial, sans-serif;
  font-weight: 400;
  line-height: 2.5rem;
}
.ant-menu-submenu-title {
  color: #333333 !important;
}

.ant-menu-title-content {
  white-space: normal;
  word-break: keep-all;
  color: #333333;
}

.ant-menu .ant-menu-item .anticon +span {
  margin-inline-start: 0;
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: hidden;
}

.ant-drawer .ant-drawer-title {
  font-size: 1.5rem;
  font-weight: 400;
  @include govuk-media-query($until: tablet) {
    font-size: 1rem;
  }
}

.ant-drawer .ant-drawer-header {
  border-bottom: 3px solid;
  border-bottom-color: var(--tenant-brand-colour, $access);
}

.ant-drawer .ant-drawer-footer {
  cursor: pointer;
  padding-bottom: 1rem;
  font-weight: 700;
  max-width: 100%;
  border-top:  2px solid $govuk-hover-colour;
  @include govuk-media-query($from: mobile) {
    margin: 3rem 0 0rem 0;
  }
}

.logout_area {
  display: flex;
  align-items: center;
}

.header_sign_out {
  width: 65%;
  margin: auto
}